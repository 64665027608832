import React from 'react'
import "./stylesheets/common.scss"
import "./stylesheets/images.scss"
import Masonry from 'react-masonry-component'
import firebase from './firebase-config'

class Home extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      images: [],
    }

    this.db = firebase.firestore()
    this.storageRef = firebase.storage().ref('album')
    this.onFileInput = this.onFileInput.bind(this)
  }

  componentDidMount() {
    this.db.collection('album').get().then((result) => {
      const images = result.docs.map(doc => doc.data().imageUrl)
      this.setState({ images })
    })
  }

  render() {
    const elements = this.state.images.map((img, index) => {
      return <div className="content-image" key={index}>
              <img src={img} width="100%"></img>
            </div>
    })

    const options = {
      itemSelector: '.content-image',
      gutter: 5,
    }

    return(
      <div className="content">
        <h1 style={{ textAlign: "center" }}>Ai & Kazuma</h1>
        <Masonry options={options}>
          { elements }
        </Masonry>
        <label className="select-album">
          アルバムを追加する
          <input type="file" onChange={this.onFileInput} className="select-album__input" multiple="multiple"></input>
        </label>
      </div>
    )
  }

  onFileInput(event) {
    for(var i = 0, file; file = event.target.files[i]; i++) {
      const uploadTask = this.storageRef.child(`${Math.random().toString(32).substring(2)}`).put(file)
      uploadTask.on('state_changed', () => { // status
      }, () => {
      }, () => { // success
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.db.collection('album').add({
            imageUrl: downloadURL,
          })

          const images = this.state.images
          images.push(downloadURL)
          this.setState({
            images
          })
        })
      })
    }
  }
}

export default Home

