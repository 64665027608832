import React from 'react'
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './Home';

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Header />
          <Route path='/' component={() => <Home />} />
          <Footer />
        </div>
      </BrowserRouter>
    )
  }
}

export default App;
