import React from 'react'
import '../stylesheets/header.scss'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isopen: false,
    }

    this.changeWindow = this.changeWindow.bind(this)
  }

  render() {
    return(
      <header className="header">
        <a href="/" className="service_name">Memory</a>
      </header>
    )
  }

  get isLogin() {
    return this.props.user !== null
  }

  changeWindow() {
    this.setState({ isopen: !this.state.isopen })
  }
}

export default Header