import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-firestore'
import 'firebase/firebase-storage'

const config = {
  apiKey: "AIzaSyCxF4Xi8oE5dth6px_HuGbG5TAm2mXHL3w",
  authDomain: "memo-b4dd4.firebaseapp.com",
  databaseURL: "https://memo-b4dd4.firebaseio.com",
  projectId: "memo-b4dd4",
  storageBucket: "memo-b4dd4.appspot.com",
  messagingSenderId: "983892770220",
  appId: "1:983892770220:web:d552edc3ff32de6c"
}

firebase.initializeApp(config)

export default firebase